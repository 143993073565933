import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import moment from 'moment';
import mqtt from 'mqtt';
import './App.css';

const client = mqtt.connect('mqtts://mqtt-dev.dns.t0.vc');

function useMQTTSubscribe(client, topic, onMessage) {
	useEffect(() => {
		if (!client || !client.connected) {
			console.log('No client / connection');
			return;
		}
		const handleMsg = (receivedTopic, message) => {
			if (receivedTopic === topic) {
				onMessage(message.toString());
			}
		};
		client.subscribe(topic);
		client.on('message', handleMsg);
		return () => {
			client.unsubscribe(topic);
			client.off('message', handleMsg);
		};
	}, [client, topic, onMessage]);
}


function LoadCell(props) {
	const { client } = props;
	const [data, setData] = useState([]);
	const [current, setCurrent] = useState(0);
	const [max, setMax] = useState(0);

	const addData = (message) => {
		setData(prevState => {
			const new_data = message.split(',').map(x => {
				const val = parseInt(x);
				setCurrent(val);
				setMax(prevState => val > prevState ? val : prevState);
				return {force: val};
			});
			return [...prevState, ...new_data].slice(-50);
		});
	};

	useMQTTSubscribe(client, 'test', addData);

	console.log(data);

	return (
		<div>
			<div className='container'>
				<p>Loadcell demo</p>
			</div>

			{data ?
				<ResponsiveContainer width='100%' height={300}>
					<LineChart data={data}>
						<XAxis
							dataKey='time'
							minTickGap={10}
						/>
						<YAxis
							domain={[0, 100]}
						/>
						<CartesianGrid strokeDasharray='3 3'/>
						<Tooltip
						/>

						<Line
							type='monotone'
							dataKey='force'
							name='lbf'
							stroke='#ff5900'
							strokeWidth={2}
							dot={false}
							animationDuration={1000}
						/>
					</LineChart>
				</ResponsiveContainer>
			:
				<p>Loading...</p>
			}

			<div className='container'>
				<p>Current: {current} lbf</p>
				<p>Max: {max} lbf</p>
			</div>
		</div>
	);
}

function App() {
	const [connected, setConnected] = useState(false);

	useEffect(() => {
		client.on('connect', () => {
			setConnected(true);
		});
	}, [client]);

	return (
		<div>
			{connected ?
				<LoadCell client={client} />
			:
				<p>Connecting...</p>
			}
		</div>
	);
}


export default App;
