// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
	box-sizing: border-box;
}

body {
	background-color: #212121;
}

	
.container {
	max-width: 40em;
	margin: 0 auto;
}

.panels:after {
	content: "";
	display: table;
	clear: both;
}

.panel {
	float: left;
	width: 23%;
	padding-bottom: 20%;
	border-radius: 5%;
	border-style: solid;
	border-width: 0.5em;
	position: relative;
	margin: 1%;
	box-sizing: border-box;
}

.panel-label {
	position: absolute;
	color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2em;
	width: 110%;
	text-align: center;
}

p {
	color: white;
	font-size: 2em;
	margin: 0.25em;
}

.recharts-wrapper p {
	color: initial;
	font-size: initial;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;AACvB;;AAEA;CACC,yBAAyB;AAC1B;;;AAGA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,WAAW;CACX,cAAc;CACd,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,UAAU;CACV,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;CACnB,mBAAmB;CACnB,kBAAkB;CAClB,UAAU;CACV,sBAAsB;AACvB;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,QAAQ;CACR,SAAS;CACT,gCAAgC;CAChC,cAAc;CACd,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,cAAc;CACd,cAAc;AACf;;AAEA;CACC,cAAc;CACd,kBAAkB;AACnB","sourcesContent":["* {\n\tbox-sizing: border-box;\n}\n\nbody {\n\tbackground-color: #212121;\n}\n\n\t\n.container {\n\tmax-width: 40em;\n\tmargin: 0 auto;\n}\n\n.panels:after {\n\tcontent: \"\";\n\tdisplay: table;\n\tclear: both;\n}\n\n.panel {\n\tfloat: left;\n\twidth: 23%;\n\tpadding-bottom: 20%;\n\tborder-radius: 5%;\n\tborder-style: solid;\n\tborder-width: 0.5em;\n\tposition: relative;\n\tmargin: 1%;\n\tbox-sizing: border-box;\n}\n\n.panel-label {\n\tposition: absolute;\n\tcolor: white;\n\ttop: 50%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\tfont-size: 2em;\n\twidth: 110%;\n\ttext-align: center;\n}\n\np {\n\tcolor: white;\n\tfont-size: 2em;\n\tmargin: 0.25em;\n}\n\n.recharts-wrapper p {\n\tcolor: initial;\n\tfont-size: initial;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
